// Dev Mode
// const base_url = import.meta.url.replace(/\/[^\/]*$/, '');
import("./utils.js").then((utils) => {
  import(utils.coreVersion()).then((core) => {
    const base_url = "https://cdn.skypack.dev/@startinblox/component-event";
    core.Helpers.importCSS(
      "https://cdn.materialdesignicons.com/3.6.95/css/materialdesignicons.min.css"
    );
    core.Helpers.importCSS(`${base_url}/css/main.css?min`);
    core.Helpers.importJS(
      "https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment.min.js"
    );

    customElements.define(
      "solid-event",
      class extends core.SolidTemplateElement {
        constructor() {
          super();
          this.loaderID = core.Helpers.uniqID();
          this.setTranslationsPath(`${base_url}/locales`);
          if (window.hubl) {
            this.localize = (key) => {
              return (
                window.hubl.intl.t("events." + key) || this.strings[key] || key
              );
            };
          }
        }

        static get propsDefinition() {
          return {
            dataSrc: "data-src",
            pastEvents: "past-events",
            visibility: "visiblecheckbox",
            visibilityregions: "visibilityregions",
            regionRange: "range-event-region",
            typeRange: "range-event-type",
            circleRange: "range-event-circle",
            uploadDir: "upload-dir",
            nestedField: "nested-field",
            extraContext: "extra-context",
            dateFormat: "date-format",
            idPrefix: "id-prefix",
            postUri: "post-uri",
            postTypesUri: "post-types-uri",
            uniq: "uniq",
          };
        }

        get dateFormat() {
          return this.props.dateFormat ? this.props.dateFormat : "DD/MM/YYYY";
        }

        get extraContext() {
          return JSON.stringify({
            account: "hd:account",
            avatar: "foaf:depiction",
            messageSet: "http://happy-dev.fr/owl/#message_set",
            author: "http://happy-dev.fr/owl/#author_user",
            title: "http://happy-dev.fr/owl/#title",
            ...JSON.parse(this.props.extraContext || "{}"),
          });
        }

        connectedCallback() {
          this.addEventListener("widgetRendered", (e) => {
            if (!e.target.classList.contains("type-form-search")) return;
            const selector = this.querySelector(
              "solid-form-multipleselect-autocompletion-label"
            );
            const hiddenSelector = Array.from(
              this.querySelectorAll(".hidden-solid-form-search")
            );
            hiddenSelector.forEach((sel) =>
              sel.dispatchEvent(new Event("formChange"))
            );
            selector.addEventListener("input", (event) => {
              const hiddenOptions = Array.from(
                hiddenSelector.map((sel) =>
                  Array.from(sel.querySelectorAll("option"))
                )
              ).flat();
              const values = event.target.component.getValue();
              hiddenOptions.forEach((opt) => (opt.selected = false));
              values.forEach((value) =>
                hiddenOptions
                  .filter((o) => JSON.parse(o.value)["@id"] === value["@id"])
                  .forEach((o) => (o.selected = true))
              );
              hiddenSelector.forEach((sel) =>
                sel.dispatchEvent(new Event("formChange"))
              );
            });
          });
          this.addEventListener("click", (e) => {
            if (e.target && e.target.closest(".pagination nav")) {
              let display = e.target.closest("solid-display");
              display.scrollIntoView(true);
            }
          });
        }

        template({
          dataSrc,
          pastEvents,
          visibility,
          visibilityregions,
          regionRange,
          nestedField,
          typeRange,
          circleRange,
          uploadDir,
          idPrefix,
          postUri,
          postTypesUri,
          uniq
        }) {
          if (!dataSrc) return "";
          // use unique ID for this component instance when provided
          if (uniq) idPrefix = uniq;
          if (!idPrefix) idPrefix = "default";
          let sourceUrl = dataSrc + (nestedField ? nestedField + "/" : "");
          if (!postUri) postUri = dataSrc;
          if (!postTypesUri) postUri = typeRange;

          const yesterday = new Date(
            new Date().setDate(new Date().getDate() - 1)
          );
          let yesterdayFormat =
            yesterday.getFullYear() +
            "-" +
            ("0" + (yesterday.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + yesterday.getDate()).slice(-2);

          return `
    <div class="solid-event">
      <script id="event-context" type="application/ld+json">
        ${this.extraContext}
      </script>
      <solid-router default-route="${idPrefix}-listevents">
        <solid-route class="d-none" name="${idPrefix}-listevents"></solid-route>
        <solid-route use-id name="${idPrefix}-eventdetail"></solid-route>
        <solid-route name="${idPrefix}-newevent"></solid-route>
        <solid-route use-id name="${idPrefix}-editevent"></solid-route>
      </solid-router>
      <section class="listevents bg-color-grey padding-bottom-xlarge" data-view="${idPrefix}-listevents" id="${idPrefix}-listevents">
        <div class="segment full padding-large sm-padding-top-small sm-padding-right-xsmall sm-padding-bottom-small sm-padding-left-xsmall border-bottom border-color-grey sm-whitespace-normal">
          <div class="segment half sm-full">
            <h2 class="text-color-heading text-uppercase text-xlarge text-letter-spacing-large">${this.localize(
              "title.events"
            )}</h2>
          </div>
          <div class="segment half sm-full text-right">
            <solid-link class="segment button text-xsmall text-bold text-uppercase text-center reversed color-secondary bordered icon icon-plus icon-margin-right-xxsmall" next="${idPrefix}-newevent">
              ${this.localize("label.post_event")}
            </solid-link>
          </div>
        </div>
        <div class="segment full padding-large sm-padding-xsmall sm-padding-top-normal whitespace-normal">
          <div class="segment half sm-full">
            <h3 class="text-color-heading text-semibold text-letter-spacing-large">${this.localize(
              "title.search_event"
            )}</h3>
            <solid-form-search
            id="${idPrefix}-solid-event-type-search-future"
            class="hidden-solid-form-search"
            fields="type, endDate"
            widget-endDate="solid-form-rangedate"
            start-value-endDate="today"
            widget-type="solid-form-multipleselect"
            range-type="${typeRange}"
            ></solid-form-search>
            
            <solid-form-search
            id="${idPrefix}-solid-event-type-search-past"
            class="hidden-solid-form-search"
            fields="type, endDate"
            widget-endDate="solid-form-rangedate"
            end-value-endDate="${yesterdayFormat}"
            widget-type="solid-form-multipleselect"
            range-type="${typeRange}"
            ></solid-form-search>
            
            <solid-form-search
            id="solid-event-type-search"
            
            class="segment block whitespace-normal form"
            range-type="${typeRange}"
            order-asc-type="name"
            fields="type"
            
            class-type="segment margin-bottom-small full sm-full sm-padding-none text-small select-shadow select-bg-white type-form-search"
            label-type="${this.localize("search.by_type")}"
            widget-type="solid-form-multipleselect-autocompletion-label"
            ></solid-form-search>
          </div>
        </div>

        <div id="${idPrefix}-loading-events" class="loader"><div></div><div></div><div></div><div></div></div>

        <solid-widget name="event-display-location-events">
          <template>
            <div class="event-place-name">
            <a href="\${await (value.address ? value.address.then(a => {
                if(!a) return '#'
                else if((new RegExp('^https?://')).test(a)) return a
                else return 'https://www.openstreetmap.org/search?query='+a
              }) : '#')}"
              class="icon icon-location-pin icon-margin-right-xxsmall margin-bottom-xsmall text-color-secondary $\{value ? '' : 'hidden'}"
              target="\${await (value.address ? value.address.then(a => a ? '_blank' : '') : '')}">
              \${await value.name}
            </a>
            </div>
          </template>
        </solid-widget>
        <solid-widget name="event-display-startdate-events">
          <template>
            <div class="margin-bottom-xxsmall">${this.localize(
              "label.startdate"
            )} <span class="margin-left-xsmall icon icon-calendar icon-margin-right-xxsmall"> \${moment(await value.toString()).format('DD.MM.YYYY')}</span>
            </div>
          </template>
        </solid-widget>
        <solid-widget name="event-display-enddate-events">
          <template>
            <div>${this.localize(
              "label.enddate"
            )} <span class="margin-left-xsmall icon icon-calendar icon-margin-right-xxsmall"> \${moment(await value.toString()).format('DD.MM.YYYY')}</span>
            </div>
          </template>
        </solid-widget>
        <solid-widget name="event-display-starttime-events">
          <template>
          <div class="icon icon-clock icon-margin-right-xxsmall"> \${value.split(':').splice(0,2).join(':')}
          </div>
          </template>
        </solid-widget>
        <solid-widget name="event-display-type">
          <template>
            <div class="event-type">\${await value.name}</div>
          </template>
        </solid-widget>
        <solid-widget name="event-display-link-event">
          <template>
            <a href='\${value}' target="_blank" class="icon icon-link  icon-margin-right-xxsmall text-color-secondary \${value ? '' : 'hidden'}">\${value.replace(/(https?:\\/\\/[\\w.-]+)\\/.*/, '$1')}</a>
          </template>
        </solid-widget>

        <solid-display
          class="segment full children children-third sm-children-full children-margin-bottom-medium sm-children-margin-bottom-xsmall children-padding-right-large children-padding-left-large sm-children-padding-none sm-whitespace-normal masonry counter pagination"

          data-src="${sourceUrl}"
          extra-context="event-context"

          fields ="segment1(img, name, startSet(startDate,startTime), endSet(endDate,endTime), type, shortDescription, location, link)"
          filtered-by="${idPrefix}-solid-event-type-search-future"

          class-segment1="segment hover bg-color-white shadow full text-top text-center whitespace-normal padding-bottom-medium"
          class-img="event-children-bg-color-primary"
          class-name="segment block whitespace-normal text-uppercase text-semibold text-color-heading text-xxlarge text-letter-spacing-larger margin-medium"
          class-startSet="segment block text-bold"
          class-startDate="segment margin-right-xsmall"
          class-startTime="segment margin-left-xsmall"
          class-endSet="segment block text-bold margin-bottom-medium"
          class-endDate="segment margin-right-xsmall"
          class-endTime="segment margin-left-xsmall"
          class-type="text-uppercase tag color-primary bordered"
          class-shortDescription="segment block whitespace-normal margin-small"

          widget-img ='solid-display-img'
          widget-startDate="event-display-startdate-events"
          widget-startTime="event-display-starttime-events"
          widget-endDate="event-display-enddate-events"
          widget-endTime="event-display-starttime-events"
          widget-type="event-display-type"
          widget-link="event-display-link-event"
          widget-location="event-display-location-events"
          paginate-by="9"
          next="${idPrefix}-eventdetail"
          loader-id="${idPrefix}-loading-events"
        ></solid-display>
        <div class="${pastEvents} border-top border-color-grey margin-top-medium bg-color-white">
          <h3 class="text-color-heading text-semibold text-letter-spacing-large margin-top-xxlarge margin-bottom-medium padding-left-xlarge sm-padding-left-xsmall">${this.localize(
            "title.past-events"
          )}</h3>
          <solid-display
            class="segment full children children-third sm-children-full children-margin-bottom-medium sm-children-margin-bottom-xsmall children-padding-right-large children-padding-left-large sm-children-padding-none sm-whitespace-normal masonry counter pagination"

            data-src="${sourceUrl}"
            extra-context="event-context"

            fields ="segment1(img, name, startSet(startDate,startTime), endSet(endDate,endTime), type, shortDescription, location, link)"
            filtered-by="${idPrefix}-solid-event-type-search-past"

            class-segment1="segment hover bg-color-grey  border-all-sides border-color-grey shadow full text-top text-center whitespace-normal padding-bottom-medium"
            class-img="event-children-bg-color-primary"
            class-name="segment block whitespace-normal text-uppercase text-semibold text-color-heading text-xxlarge text-letter-spacing-larger margin-medium margin-bottom-xsmall"
            class-startSet="segment block text-bold"
            class-startDate="segment margin-right-xsmall"
            class-startTime="segment margin-left-xsmall"
            class-endSet="segment block text-bold margin-bottom-medium"
            class-endDate="segment margin-right-xsmall"
            class-endTime="segment margin-left-xsmall"
            class-type="text-uppercase tag color-primary bordered"
            class-shortDescription="segment block whitespace-normal margin-small"

            widget-img ='solid-display-img'
            widget-startDate="event-display-startdate-events"
            widget-startTime="event-display-starttime-events"
            widget-endDate="event-display-enddate-events"
            widget-endTime="event-display-starttime-events"
            widget-type="event-display-type"
            widget-link="event-display-link-event"
            widget-location="event-display-location-events"
            order-desc="endDate"
            paginate-by="9"
            next="${idPrefix}-eventdetail"
            loader-id="${idPrefix}-loading-events"
          ></solid-display>
        <div>
      </section>
      <section data-view="${idPrefix}-eventdetail" id="${idPrefix}-eventdetail" class="eventdetail margin-bottom-xxlarge" hidden="">

        <div id="${idPrefix}-loading-event" class="loader"><div></div><div></div><div></div><div></div></div>

        <solid-widget name="event-multiple-label-ifnotvoid">
          <template>
            <label class="\${value ? '' : 'hidden'}">\${label}</label>
            <solid-display data-src="\${value}" fields="name"></solid-display>
          </template>
        </solid-widget>

        <solid-display
          fields ="name, info(img, infotext(location, startSet(startDate,startTime), endSet(endDate,endTime), type, circle, shortDescription, link, @id)), longDescription"
          bind-resources
            
          class="container-1200"
          class-name="event-title segment block text-center text-xxlarge text-uppercase text-bold margin-xxlarge text-color-heading whitespace-normal"
          class-info="segment full margin-bottom-xxsmall sm-whitespace-normal sm-margin-none"
          class-img="segment half sm-full whitespace-normal event-children-bg-color-primary text-center padding-small"
          class-infotext="segment half sm-full whitespace-normal text-top margin-none margin-top-medium margin-left-medium margin-bottom-medium sm-margin-none sm-margin-top-medium sm-margin-bottom-medium sm-padding-small"
          class-location="segment block margin-bottom-small"
          class-startSet="segment block margin-bottom-small"
          class-startDate="segment margin-right-small"
          class-startTime="segment"
          class-endSet="segment block margin-bottom-small"
          class-endDate="segment margin-right-small"
          class-endTime="segment"
          class-shortDescription="segment block whitespace-normal margin-bottom-small"
          class-type="segment margin-bottom-xsmall margin-top-xsmall text-uppercase tag color-primary bordered"
          class-circle="segment block "
          class-longDescription="segment block whitespace-normal margin-top-medium margin-bottom-medium padding-small"

          widget-img ='solid-display-img'
          widget-startDate="event-display-startdate-events"
          widget-startTime="event-display-starttime-events"
          widget-endDate="event-display-enddate-events"
          widget-endTime="event-display-starttime-events"
          widget-type="event-display-type"
          widget-id="event-edit"
          widget-circle="event-multiple-label-ifnotvoid"
          label-circle="${this.localize("label.linked_circle")}"
          widget-link="event-display-link-event"
          widget-location="event-display-location-events"
          widget-longDescription="solid-display-div-multiline"

          loader-id="${idPrefix}-loading-event"

        ></solid-display>
        <solid-widget name="event-edit">
          <template>
            <solid-ac-checker permission="acl:Write" data-src="\${value}">
              <div class="segment full margin-bottom-small margin-top-medium">
                <solid-link data-src="\${value}" next="${idPrefix}-editevent" class="segment sm-full button text-xsmall text-bold text-uppercase text-center reversed color-secondary bordered icon icon-pencil icon-margin-right-xxsmall">
                    ${this.localize("label.edit_event")}
                </solid-link>
              </div>
              <div class="segment full margin-bottom-small">
                <solid-delete class="segment sm-full button text-xsmall text-bold text-uppercase text-center color-secondary bordered" data-src="\${value}"
                    data-label="${this.localize("label.delete_event")}"
                    next="${idPrefix}-listevents"
                    confirmation-message="${this.localize(
                      "confirm.delete_event"
                    )}">
                </solid-delete>
              </div>
            </solid-ac-checker>
          </template>
        </solid-widget>
        <div class="container-1200">
          <solid-link next="${idPrefix}-listevents" class="segment button text-xsmall text-bold text-uppercase text-center reversed color-secondary bordered icon icon-arrow-left icon-margin-right-xxsmall margin-left-small margin-top-medium">
          ${this.localize("button.back_to_list")}
        </solid-link>
        </div>
      </section>
      <section data-view="${idPrefix}-newevent" id="${idPrefix}-newevent" class="newevent" hidden="">
        <div class="segment full padding-large sm-padding-top-small sm-padding-right-xsmall sm-padding-bottom-small sm-padding-left-xsmall border-bottom border-color-grey bg-color-white">
          <div class="segment half sm-full">
            <h2 class="margin-none text-color-heading text-uppercase text-xlarge text-letter-spacing-large">${this.localize(
              "title.create_new_event"
            )}</h2>
          </div>
          <div class="segment half sm-hidden text-right">
            <solid-link class="backlink" bind-resources="" next="${idPrefix}-listevents">${this.localize(
            "title.create_backlink"
          )}</solid-link>
          </div>
        </div>

        <div class="segment full padding-large sm-padding-xsmall sm-padding-top-medium whitespace-normal bg-color-white">
          <solid-widget name="event-form-starttime-label">
            <template>
              <label>
                ${this.localize("label.start_time")}*
              </label>
              <input data-holder="" type="time" value="\${value.split(':').splice(0,2).join(':')}" name="startTime"></input>
            </template>
          <solid-widget name="event-form-endtime-label">
            <template>
              <label>
                ${this.localize("label.end_time")}*
              </label>
              <input data-holder="" type="time" value="\${value.split(':').splice(0,2).join(':')}" name="startTime"></input>
            </template>
          </solid-widget>
          <solid-form class="form"
            data-src="${postUri}"
            fields ="name, startDate, startTime, type, endDate, endTime, region, visible, img, shortDescription, longDescription, location.name, location.address, link, circle"

            class-name="segment margin-bottom-medium full sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-type="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-startDate="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-startTime="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-endDate="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-endTime="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-visible="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal ${visibility}"
            class-region="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal ${visibilityregions}"
            class-img="segment block center margin-bottom-medium half sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-shortDescription="segment margin-bottom-medium full sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-longDescription="segment margin-bottom-medium full sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-location.name="segment margin-bottom-medium half sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-location.address="segment margin-bottom-medium half sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-link="segment margin-bottom-medium half sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-circle="segment margin-bottom-medium half sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"

            widget-name="solid-form-label-text"
            widget-startDate="solid-form-date-label"
            widget-startTime="event-form-starttime-label"
            value-startTime="12:00"
            widget-endDate="solid-form-date-label"
            widget-endTime="event-form-endtime-label"
            value-endTime="12:00"
            widget-visible="solid-form-checkbox-label"

            range-region="${regionRange}"
            widget-region="event-solid-form-dropdown-label-addable"
            addable-region-data-src="${regionRange}"
            addable-region-fields="name"
            addable-region-placeholder-name="${this.localize(
              "label.add_event_region"
            )}"
            addable-region-widget-name="solid-form-text-placeholder"
            addable-region-submit-button="${this.localize(
              "button.save_event_region"
            )}"
            addable-region-no-result="${this.localize(
              "label.event_region_no_result"
            )}"
            placeholder-region="${this.localize(
              "label.select_region_of_event"
            )}"

            range-type="${typeRange}"
            widget-type="solid-form-placeholder-dropdown-label"
            addable-type-data-src="${typeRange}"
            addable-type-fields="name"
            addable-type-placeholder-name="${this.localize(
              "label.add_event_type"
            )}"
            addable-type-widget-name="solid-form-text-placeholder"
            addable-type-submit-button="${this.localize(
              "button.save_event_type"
            )}"
            addable-type-no-result="${this.localize(
              "label.event_type_no_result"
            )}"
            placeholder-type="${this.localize("label.select_type_of_event")}"

            widget-img="solid-form-image-label"
            widget-shortDescription="solid-form-textarea-label"
            maxlength-shortDescription="150"
            widget-longDescription="solid-form-textarea-label"
            range-circle="${circleRange}"
            widget-circle="solid-form-placeholder-dropdown-label"

            label-img="${this.localize("label.image")}"
            label-circle="${this.localize("label.circle")}"
            placeholder-circle="${this.localize("label.select_circle")}"
            label-startDate="${this.localize("label.start_date")}*"
            label-startTime="${this.localize("label.start_time")}*"
            label-endDate="${this.localize("label.end_date")}*"
            label-endTime="${this.localize("label.end_time")}*"
            label-visible="${this.localize("label.visible")}"
            label-type="${this.localize("label.type_of_event")}*"
            label-region="${this.localize("label.region_of_event")}*"
            

            label-name="${this.localize("label.event_name")}*"
            label-shortDescription="${this.localize(
              "label.short_description"
            )}*"
            label-longDescription="${this.localize("label.long_description")}*"
            label-location.name="${this.localize("label.location")}*"
            label-location.address="${this.localize("label.address")}"
            label-link="${this.localize("label.link")}"

            required-startDate
            required-startTime
            required-endDate
            required-endTime
            required-name
            required-shortDescription
            required-longDescription
            required-location.name
            required-type

            pattern-name=".{1,50}"
            title-name="${this.localize("validation.name_no_more")}"
            pattern-shortDescription=".{1,250}"
            title-shortDescription="${this.localize(
              "validation.shortDescription_no_more"
            )}"
            pattern-link=".{0,150}"
            title-link="${this.localize("validation.link_no_more")}"

            upload-url-img="${uploadDir}"
            value-img="${base_url}/img/defaulteventtrans.svg"

            data-js-type="event"
            next="${idPrefix}-listevents"
            submit-button="${this.localize("button.publish_event")}"
            class-submit-button="segment float-right button text-xsmall text-bold text-uppercase text-center reversed color-secondary bordered"
          ></solid-form>
        </div>
      </section>
      <section data-view="${idPrefix}-editevent" id="${idPrefix}-editevent" class="editevent" hidden="">
        <div class="segment full padding-large sm-padding-top-small sm-padding-right-xsmall sm-padding-bottom-small sm-padding-left-xsmall border-bottom border-color-grey bg-color-white">
          <div class="segment half sm-full">
            <h2 class="margin-none text-color-heading text-uppercase text-xlarge text-letter-spacing-large">
              <solid-display bind-resources fields="name"></solid-display>
            </h2>
          </div>
          <div class="segment half sm-hidden text-right">
            <solid-link class="backlink" bind-resources="" next="${idPrefix}-eventdetail">${this.localize(
            "title.edit_backlink"
          )}</solid-link>
          </div>
        </div>

        <div class="segment full padding-large sm-padding-xsmall sm-padding-top-medium whitespace-normal bg-color-white">
          <solid-form class="form"
            bind-resources
            fields ="name, startDate, startTime, type, endDate, endTime, region, visible, img, shortDescription, longDescription, location.name, location.address, link, circle"

            class-name="segment margin-bottom-medium full sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-type="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-startDate="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-startTime="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-endDate="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-endTime="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-visible="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal ${visibility}"
            class-region="segment margin-bottom-medium third sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal ${visibilityregions}"
            class-img="segment block center margin-bottom-medium half sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-shortDescription="segment margin-bottom-medium full sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-longDescription="segment margin-bottom-medium full sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-location.name="segment margin-bottom-medium half sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-location.address="segment margin-bottom-medium half sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-link="segment margin-bottom-medium half sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-circle="segment margin-bottom-medium half sm-full padding-right-small sm-padding-none text-small text-semibold text-uppercase text-color-heading whitespace-normal"
            class-submit-button="segment float-right button text-xsmall text-bold text-uppercase text-center reversed color-secondary bordered"

            widget-name="solid-form-label-text"
            widget-startDate="solid-form-date-label"
            widget-startTime="event-form-starttime-label"
            widget-endDate="solid-form-date-label"
            widget-endTime="event-form-endtime-label"
            widget-visible="solid-form-checkbox-label"
            range-region="${regionRange}"
            widget-region="solid-form-placeholder-dropdown-label"
            placeholder-region="${this.localize(
              "label.select_region_of_event"
            )}"
            range-type="${typeRange}"
            widget-type="solid-form-placeholder-dropdown-label"
            placeholder-type="${this.localize("label.select_type_of_event")}"

            widget-img="solid-form-image-label"
            widget-shortDescription="solid-form-textarea-label"
            maxlength-shortDescription="150"
            widget-longDescription="solid-form-textarea-label"
            range-circle="${circleRange}"
            widget-circle="solid-form-placeholder-dropdown-label"

            label-img="${this.localize("label.image")}"
            label-circle="${this.localize("label.circle")}"
            placeholder-circle="${this.localize("label.select_circle")}"
            label-startDate="${this.localize("label.start_date")}*"
            label-endDate="${this.localize("label.end_date")}*"
            label-visible="${this.localize("label.visible")}"
            label-region="${this.localize("label.region_of_event")}"
            label-type="${this.localize("label.type_of_event")}*"
            label-name="${this.localize("label.event_name")}*"
            label-shortDescription="${this.localize(
              "label.short_description"
            )}*"
            label-longDescription="${this.localize("label.long_description")}*"
            label-location.name="${this.localize("label.location")}*"
            label-location.address="${this.localize("label.address")}"
            label-link="${this.localize("label.link")}"

            required-startDate
            required-startTime
            required-endDate
            required-endTime
            required-name
            required-shortDescription
            required-longDescription
            required-location.name
            required-type

            pattern-name=".{1,50}"
            title-name="${this.localize("validation.name_no_more")}"
            pattern-shortDescription=".{1,250}"
            title-shortDescription="${this.localize(
              "validation.shortDescription_no_more"
            )}"
            pattern-link=".{0,150}"
            title-link="${this.localize("validation.link_no_more")}"

            upload-url-img="${uploadDir}"
            data-js-type="event"
            next="${idPrefix}-eventdetail"
            submit-button="${this.localize("button.save_event")}"
          ></solid-form>
        </div>
      </section>
    </div>
    `;
        }
      }
    );
  });
});
